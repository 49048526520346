<!--
 * @Author: wxb
 * @Date: 2021-06-22 00:28:28
 * @LastEditTime: 2021-08-16 12:31:05
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Project\originalNotice.vue
-->
<template>
  <div class="containner">
    <div class="content">
      <div class="search-info">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.keyword"
                      placeholder="输入关键词搜索..."
                      clearable
                      @keyup.enter.native="searchNotice"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchNotice">搜索</button>
          </div>
        </div>
        <div class="search-senior">
          <el-link :underline="false"
                   @click="showSenior=!showSenior">筛选 <i class="el-icon-arrow-up"
               v-show="showSenior"></i>
            <i class="el-icon-arrow-down"
               v-show="!showSenior"></i>
          </el-link>
        </div>
      </div>
      <div class="project-search-senior"
           v-show="showSenior">
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">公告类型：</div>
          <div class="project-search-senior-item-content">
            <div class="notice-type-content"
                 :class="{autoH:autoH.protype}">
              <div class="notice-type-content-item"
                   :class="index===activeNoticeType ? 'active' : ''"
                   v-for="(item,index) in noticeTypeList"
                   :key="index"
                   @click="handleClick4NoticeType(index)">{{item}}</div>
            </div>
            <div class="showmore-tools"
                 @click="autoH.protype = !autoH.protype">
              <span v-show="!autoH.protype"> 更多<i class="el-icon-arrow-down"></i></span>
              <span v-show="autoH.protype"> 收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>
        </div>
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">发布时间：</div>
          <div class="project-search-senior-item-content">
            <div class="notice-type-content">
              <div class="notice-type-content-item"
                   :class="index===activePublishDate ? 'active' : ''"
                   v-for="(item,index) in publishTypeList"
                   :key="index"
                   @click="handleClick4PublishType(index)">{{item}}</div>
              <div class="publish-time">
                <el-date-picker v-model="publishDate"
                                type="daterange"
                                unlink-panels
                                size="small"
                                value-format="yyyy-MM-dd"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="handlePublishChange">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">项目地区：</div>
          <div class="project-search-senior-item-content">
            <div class="area-content"
                 :class="{autoH:autoH.area}">
              <div class="area-content-item"
                   :class="index===activeArea ? 'active' : ''"
                   v-for="(item,index) in province"
                   :key="index"
                   @click="handleClick4Area(index)">{{item.DICTIONARYITEM_ITEMNAME}}</div>
            </div>
            <div class="showmore-tools"
                 @click="autoH.area = !autoH.area">
              <span v-show="!autoH.area"> 更多<i class="el-icon-arrow-down"></i></span>
              <span v-show="autoH.area"> 收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="project"
           v-show="!noData && canSee">
        <div class="project-list">
          <div class="project-list-title">公告列表</div>
          <div class="project-list-info">
            <div class="project-list-info-item"
                 @click="goToAnalysis(item.YSGGXX_GGLJ)"
                 v-for="(item,index) in resultLst"
                 :key="index">
              <div class="project-info">
                <div class="project-title">
                  {{item.YSGGXX_GGBT}}
                </div>
                <div class="project-time">{{item.YSGGXX_GGFBRQ}} 发布</div>
              </div>
              <div class="project-tag-btn">
                <div class="project-tag">
                  <el-tag size="small"
                          type="info">{{item.YSGGXX_GGLX}}</el-tag>
                  <el-tag size="small">{{item.PROVINCENAME}}</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="project-page">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="total"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage">
            <span class="current-page">{{currentPage}}</span>
          </el-pagination>
        </div>
      </div>
      <noData tips="哎呀，未查询到相关公告信息"
              v-show="noData"></noData>
      <viporlogin bgStr="1"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getYsggxxList } from '@/api/project'
import { dateFormat } from '@/utils/common'
import dialogviporlogin from '@/components/dialogviporlogin'
export default {
  name: 'originalNotice',
  data() {
    return {
      noData: false,
      canSee: true,
      viporlogin: 'login',
      searchForm: {
        keyword: '',
        noticeType: '',
        starttime: '',
        endtime: '',
        proivnce: '',
        start: 0,
        limit: 10
      },
      noticeTypeList: ['全部', '中标/成交公告', '中标/成交公示', '中标候选人公告', '中标候选人公示', '中标公告', '区级公告中标公告', '区级公告招标公告', '单一来源公示', '变更公告', '合同公告', '合同公示', '工程中标公告', '工程建设中标公告', '工程建设招标公告', '工程建设流标/终止公告', '工程建设澄清/变更公告', '工程招标公告', '市县合同公示', '市县采购公告', '市县采购结果公告', '市县需求公告', '市县验收公示', '市级公告中标公告', '市级公告招标公告', '废标/终止公告', '废标公告', '意向公告', '报建公告', '招标/资审公告', '招标/预审公告', '招标公告', '招标预公告', '政府采购中标公告', '政府采购公告', '政府采购废标/终止公告', '政府采购招标公告', '政府采购更正公告', '政府采购终止公告', '政府采购结果公告', '更正公告', '流标/终止公告', '澄清/变更公告', '省合同公开', '省采购公告', '省采购结果公告', '省需求公告', '省需求公开', '省验收公示', '终止公告', '结果公告', '资格预审公告', '采购公告', '采购前公告', '采购意向公告', '采购需求公告', '需求公告', '非招标公告', '预审公告', '验收公告', '验收公示'],
      activeNoticeType: 0,
      activePublishDate: 0,
      publishTypeList: ['全部', '最近七天', '最近30天', '最近一年'],
      publishDate: '',
      activeArea: 0,
      resultLst: [],
      total: 0,
      currentPage: 1,
      showSenior: true,
      autoH: {
        protype: true,
        area: true
      }
    }
  },
  components: {
    noData,
    viporlogin,
    dialogviporlogin
  },
  methods: {
    // 点击公告类型
    handleClick4NoticeType(index) {
      if (!this.canSee) {
        return
      }
      this.activeNoticeType = index
      const noticeType = this.noticeTypeList[index]
      if (noticeType === '全部') {
        this.searchForm.noticeType = ''
      } else {
        this.searchForm.noticeType = noticeType
      }
      // 调用搜索方法
      this.searchNotice()
    },
    // 发布日期选择
    handleClick4PublishType(index) {
      if (!this.canSee) {
        return
      }
      this.activePublishDate = index
      if (index === 0) {
        this.searchForm.starttime = ''
        this.searchForm.endtime = ''
      } else if (index === 1) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endtime = dateFormat('YYYY-mm-dd', end)
      } else if (index === 2) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endtime = dateFormat('YYYY-mm-dd', end)
      } else if (index === 3) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endtime = dateFormat('YYYY-mm-dd', end)
      }
      this.publishDate = ''
      this.searchNotice()
    },
    // 发布时间变化
    handlePublishChange(publishDate) {
      if (!this.canSee) {
        return
      }
      if (publishDate) {
        this.activePublishDate = ''
        this.searchForm.starttime = publishDate[0]
        this.searchForm.endtime = publishDate[1]
      } else {
        this.activePublishDate = 0
        this.searchForm.starttime = ''
        this.searchForm.endtime = ''
      }
      this.searchNotice()
    },
    goToAnalysis(link) {
      const authObj = authenticate('orginalNotice:click', this.memberType)
      if (authObj.flag) {
        window.open(link, '_blank')
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    // 地区点击
    handleClick4Area(index) {
      if (!this.canSee) {
        return
      }
      this.activeArea = index
      const proivnce = this.province[index].DICTIONARYITEM_ITEMNAME
      if (proivnce === '全国') {
        this.searchForm.proivnce = ''
      } else {
        this.searchForm.proivnce = proivnce
      }
      this.searchNotice()
    },
    initPage() {
      this.getOriginalNoticeList()
    },
    getOriginalNoticeList() {
      getYsggxxList(this.searchForm).then(res => {
        if (res.ok) {
          if (res.cnt > 0) {
            this.resultLst = res.lst
            // 条数限制
            if (res.cnt > 100) {
              this.total = 100
            } else {
              this.total = res.cnt
            }
            this.noData = false
          } else {
            this.noData = true
          }
        } else {
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(res => {
        console.log(res)
      })
    },
    // 搜索
    searchNotice() {
      if (!this.canSee) {
        return
      }
      this.searchForm.start = 0
      this.currentPage = 1
      this.getOriginalNoticeList()
    },
    // 处理分页
    handleCurrentChange(val) {
      if (getToken()) {
        this.searchForm.start = (val - 1) * this.searchForm.limit
        this.getOriginalNoticeList()
      } else {
        this.$refs.dialogviporlogin.handleShow('login')
        this.currentPage = 1
      }
    },
    authCheck() {
      const authObj = authenticate('orginalNotice', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  },
  mounted() {
    if (this.province.length === 0) {
      this.$store.dispatch('getProvince')
    }

    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType',
      province: 'province'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 115rem;
  margin: auto;
}
// 搜索
.search-info {
  margin: 0 auto;
  width: 700px;
  display: flex;

  .search-senior {
    flex: 1;
    .el-link {
      width: 80px;
      height: 40px;
      font-size: 14px;
    }
  }
}
// 搜索框
.project {
  &-search {
    background: #ffffff;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
    &-item {
      display: flex;
      font-size: 14px;
      padding: 18px 0px 10px;
      &:not(:last-child) {
        border-bottom: 1px dashed #e0e0e0;
      }
      &-title {
        width: 100px;
        color: #686868;
        text-align: right;
        padding: 4px 15px 4px 0px;
      }
      &-content {
        flex: 1;
        display: flex;
        .autoH {
          height: auto !important;
        }

        .notice-type-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }

        // 更多操作
        .showmore-tools {
          width: 60px;
          padding: 4px 7px;
          cursor: pointer;
          color: #686868;
        }

        .price-range {
          display: flex;
          width: 500px;
          &-item {
            flex: 1;
            &-delive {
              width: 20px;
              line-height: 28px;
              text-align: center;
            }

            &-btn {
              width: 100px;
              margin-left: 5px;
            }
          }
        }

        .publish-time {
          margin-top: -5px;
        }

        .area-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

// 标讯列表
.project {
  &-list {
    margin-top: 10px;
    background: #ffffff;

    &-title {
      padding: 10px 15px;
      border-left: 2px solid #398ce2;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
    }

    &-info {
      margin-top: 10px;
      padding: 10px 15px 0;
      .project-list-info-item {
        margin-bottom: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        border-bottom: 1px solid #ddd;

        .project {
          &-info {
            display: flex;
            font-size: 14px;
          }
          &-title {
            flex: 1;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            a {
              cursor: pointer;
              &:hover {
                color: #398ce2;
              }
            }
          }
          &-time {
            width: 160px;
          }

          &-tag-btn {
            margin-top: 10px;
            display: flex;
            .project {
              &-tag {
                flex: 1;
                .el-tag {
                  margin-right: 5px;
                }
              }
              &-btn {
                width: 100px;
                .depth-analysis {
                  background-color: #009c95 !important;
                  border-color: #009c95 !important;
                  color: #fff !important;
                  border: 1px solid #009c95;
                  cursor: pointer;
                  padding: 2px 5px;
                  border-radius: 2x;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 分页
.project-page {
  padding: 10px;
  text-align: right;
}
</style>
